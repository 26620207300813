import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

const VideoScroll = ({ list, onTitleChange, toggleOpen, isOpen }) => {
  const [isBlur, setIsBlur] = useState(false);

  useEffect(() => {
    if (onTitleChange) {
      onTitleChange(list[0]); // Set the first video's title initially
    }
  }, [onTitleChange, list]);

  const makeBlur = () => {
    setIsBlur(true);
  };
  const makeClear = () => {
    setIsBlur(false);
  };

  const getClassForIndex = (index) => {
    switch (index % 8) {
      case 0:
        return "videoIndex0";
      case 1:
        return "videoIndex1";
      case 2:
        return "videoIndex2";
      case 3:
        return "videoIndex3";
      case 4:
        return "videoIndex4";
      default:
        return ""; // Fallback to an empty class if needed
    }
  };

  return (
    <div className="relative whitespace-nowrap flex 3xl:justify-center pt-[142px] px-[20px] mb-[220px] lg:mb-[196px] lg:px-[80px]">
      <div className="flex flex-col gap-y-[200px] lg:gap-y-[196px]">
        {list.map((item, index) => (
          <div index={index} key={item.id} className={getClassForIndex(index)}>
            <Link
              href={`/moving-image/${item.slug.current}`}
              className="w-full max-w-lg flex flex-col items-start"
            >
              <video
                onMouseEnter={makeBlur}
                onMouseLeave={makeClear}
                className={
                  isBlur ? "postBlur max-h-full" : "NopostBlur max-h-full"
                }
                autoPlay
                playsInline
                loop
                muted
                onClick={toggleOpen}
              >
                <source
                  src={item.videoThumb.url}
                  type="video/mp4"
                  alt={item.videoThumb.altTitle}
                />
              </video>
              <p className="font-caslon text-xs lg:text-base capitalize pt-2 group-hover:font-casloni text-center">
                {item.title}
              </p>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoScroll;
